// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

@mixin heading($fontSize, $fontWeight, $lineHeight) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  color: $headings-color;
}


h1,
.h1 {
  @include heading($h1-font-size, $font-weight-bold, $h1-line-height);
}

h2,
.h2 {
  @include heading($h2-font-size, $font-weight-bold, $h2-line-height);
}

h3,
.h3 {
  @include heading($h3-font-size, $font-weight-medium, $h3-line-height);
}

h4,
.h4 {
  @include heading($h4-font-size, $font-weight-light, $h4-line-height);
}

h5,
.h5 {
  @include heading($h5-font-size, $font-weight-bold, $h5-line-height);
}
