a {
  color: rgba($bd-link-color, .99);

  @include hover {
    color: $bd-link-hover-color;
  }

  &.disabled,
  &[disabled],
  &[disabled="disabled"] {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: .55;

    &:focus {
      text-decoration: none;
    }
  }
}

ul > li {
  &.disabled {
    cursor: not-allowed !important;
  }
}

@mixin external-link($icon) {
  color: $bd-link-external;
  &::after {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 2px;
    vertical-align: middle;
    content: "";
    background: url("../../images/icons/#{$icon}.png") no-repeat;
    background-size: contain;
  }

  &:hover {
    color: $bd-link-external-hover;
    border-bottom: 1px solid $bd-link-external-hover;
  }
}

@mixin content-links() {
  a:not([data-type="image"]):not([data-anchorjs-icon="#"]) {
    color: $bd-link-default;
    border-bottom: none;
    transition: color .3s ease-in-out, border-bottom .3s ease-in-out;

    &:hover {
      color: $bd-link-default-hover;
      text-decoration: none;
      border-bottom: 1px solid $bd-link-default-hover;
    }


    &[href^="https://g.codefresh.io/"] {
      @include external-link("codefresh-leaves");
    }

    &:not([href^="https://g.codefresh.io/"]):not([href*="/docs/"]):not([href^="#"]){
      @include external-link("external-link");
    }

    &[href^="https://www.youtube.com/"] {
      &::after {
        display: none !important;
      }
    }
  }
}
