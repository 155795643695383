//
// Footer
//

.bd-footer {
  font-size: 85%;
  text-align: center !important;
  background-color: #2a2b3d !important;

  a {
    color: #8c91c0;
    border-bottom: 1px solid  rgba($cf-dark-alt, .2);
    transition: background .2s ease, border-color, .2s ease;

    &:hover,
    &:focus {
      color: #8c91c0;
      text-decoration: none;
      border-bottom-color: #8c91c0;
    }
  }

  p {
    margin-bottom: 0;
    color: rgba($white, .5) !important;
  }

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;

  li {
    display: inline-block;

    + li {
      margin-left: 1rem;
    }
  }
}
