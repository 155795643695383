// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

code.highlighter-rouge {
  color: #ad6800;
  background-color: #fffbe6;
}
