// clipboard.js
//
// JS-based `Copy` buttons for code snippets.

.bd-clipboard {
  position: relative;
  display: none;
  float: right;

  + .highlight {
    margin-top: 0;
  }
}

.btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: $cf-dark-alt;
  cursor: pointer;
  background-color: $light;
  border: 0;
  border-top-right-radius: .25rem;
  border-bottom-left-radius: .25rem;

  @include media-breakpoint-up(md) {
    right: -1rem;
  }

  @include media-breakpoint-up(xxl) {
    top: 0;
    right: -1.5rem;
  }

  &:hover {
    color: $white;
    background-color: $bd-topaz-main;
  }
}

@media (min-width: 768px) {
  .bd-clipboard {
    display: block;
  }
}
