// stylelint-disable declaration-no-important

.bd-masthead {
  position: relative;
  padding: 1rem ($grid-gutter-width / 2);
  background: #2a2b3d;

  h1 {
    line-height: 1;
  }

  .cf-badge-doc {
    color: #5fffbf;
    text-transform: none !important;
    vertical-align: middle;
  }

  .btn {
    padding: .5rem 1rem;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    border-radius: 4px;
  }


  .btn-primary {
    color: $white;
    background-color: #35384b;
    border-color: #35384b;
  }

  .btn-secondary {
    color: #5fffbf;
    background-color: #2a2b3d;
    border: 1px solid #5fffbf;
  }

  .carbonad {
    margin-top: 0 !important;
    margin-bottom: -3rem !important;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .carbonad {
      margin-bottom: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 4rem;
    }

    .carbonad {
      margin-top: 3rem !important;
    }
  }

  &.bd-masthead-home {
    position: relative;

    h2 {
      font-size: 40px;
      text-shadow: 0 1px 2px rgba($bd-dark, .35);
    }

    .lead {
      color: $cf-light;
    }

    &::after,
    &::before {
      position: absolute;
      top: 0;
      z-index: 0;
      display: block;
      width: 50vw;
      height: 100%;
      min-height: 370px;
      content: "";
      background-repeat: no-repeat;
      background-size: auto 100%;
    }

    .banner-home-inner {
      position: relative;
      z-index: 1;
    }

    @include media-breakpoint-down(md) {
      h2 {
        font-size: 1.875rem;
      }
    }
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  position: relative;
  background: $bd-doc-content-bg;

  .followup-card-item {
    background-color: $bd-doc-content-bg;

    h4 {
      margin-bottom: 1rem;
    }

    a {
      color: $cf-dark-alt;

      &:hover {
        color: $bd-topaz-main;
      }
    }
  }

  .bd-clipboard { display: none; }

  .highlight {
    padding: .5rem 0;
    background-color: transparent;
  }
}
