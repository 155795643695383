// stylelint-disable declaration-block-single-line-max-declarations

.highlight { background-color: #264a54; }
.hll { background-color: #ffc; }
.c { color: #546e74; }
.k { color: #e8f7f2; }
.o { color: #e8f7f2; }
.p { color: #3da693; }
.cm { color: #546e74; }
.cp { color: #099; }
.c1 { color: #546e74; }
.cs { color: #546e74; }
.gd { background-color: #fcc; border: 1px solid #ff9da4; }
.ge { font-style: italic; }
.gr { color: #f7878e; }
.gh { color: #030; }
.gi { background-color: #cfc; border: 1px solid #0c0; }
.go { color: #aaa; }
.gp { color: #009; }
.gu { color: #fcc; }
.gt { color: #9c6; }
.kc { color: #3f80d0; }
.kd { color: #e8f7f2; }
.kn { color: #3f80d0; }
.kp { color: #3f80d0; }
.kr { color: #3f80d0; }
.kt { color: #078; }
.m { color: #f78132; }
.s { color: #d1f1a9; }
.na { color: #e8f7f2; }
.nb { color: #e8f7f2; }
.nc { color: #f57f7f; }
.no { color: #5ac471; }
.nd { color: #99f; }
.ni { color: #999; }
.ne { color: #ff9da4; }
.nf { color: #de5aff; }
.nl { color: #d695ff; }
.nn { color: #0cf; }
.nt { color: #f57f7f; }
.nv { color: #d1f1a9; }
.nx { color: #68bbde; }
.ow { color: #000; }
.pi { color: #e8f7f2; }
.w { color: #bbb; }
.mf { color: #f78132; }
.mh { color: #f78132; }
.mi { color: #f78132; }
.mo { color: #f78132; }
.sb { color: #d1f1a9; }
.sc { color: #d1f1a9; }
.sd { font-style: italic; color: #d1f1a9; }
.s2 { color: #76c47e; }
.se { color: #d1f1a9; }
.sh { color: #d1f1a9; }
.si { color: #3da693; }
.sx { color: #d1f1a9; }
.sr { color: #3aa; }
.s1 { color: #d1f1a9; }
.ss { color: #fc3; }
.bp { color: #366; }
.vc { color: #033; }
.vg { color: #033; }
.vi { color: #033; }
.il { color: #f78132; }


.si + .nx,
.nx + .p + .nx,
.si + .nx + .p + .nx{
  color: #e8f7f2;
}

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }

.highlight pre {
  code.language-bash,
  code.language-sh {
    color: #c1be61;
  }
}

.language-bash::before,
.language-sh::before {
  color: #f57f7f;
  content: "$ ";
  user-select: none;
}

.language-powershell::before {
  color: #f57f7f;
  content: "PM> ";
  user-select: none;
}
