//
// Main navbar
//

.bd-navbar {
  min-height: 50px;
  background: #212437;

  @include media-breakpoint-down(md) {
    .navbar-nav-scroll {
      position: relative;
      max-width: 100%;
      margin-top: 0;
      overflow: hidden;
      font-size: .875rem;

      .navbar-nav {
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        pointer-events: none;
        content: "";
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), $cf-dark);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar-nav-scroll {
      padding-top: .6rem;
      padding-bottom: .6rem;
    }
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
      z-index: 1071; // over everything in bootstrap
    }
  }

  @include media-breakpoint-up(md) {
    .navbar-nav-scroll {
      margin-left: 2rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav-scroll {
      margin-left: 6.8rem;
    }
  }

  .cf-badge-doc {
    font-size: 16px;
    color: #5fffbf;
    background: $cf-dark;
  }

  .navbar-nav {
    .nav-item {
      padding-right: 1.2rem;
      margin-right: 1.5rem;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        padding-right: 0;
        margin-right: 0;
      }
    }

    .nav-link {
      position: relative;
      height: 35px;
      padding: 0;
      font-size: .875rem;
      font-weight: 400;
      line-height: 24px;
      color: $bd-white;
      background-color: transparent;
      transition: background-color .1s linear, color .1s linear;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 0;
        content: "";
        background-color: transparent;
        transition: background-color .1s linear, height .1s linear;
      }

      @include media-breakpoint-up(md) {
        height: 60px;
        padding: 0 1rem;
        line-height: 60px;
        text-wrap: nowrap;
      }

      &.active,
      &:hover {
        color: $bd-topaz-main;
        text-wrap: nowrap;

        &::after {
          height: 2px;
          content: "";
          background-color: $bd-topaz-main;

          @include media-breakpoint-up(md) {
            height: 4px;
          }
        }
      }

    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown-menu {
    font-size: .875rem;
  }

  .dropdown-item.active {
    font-weight: 500;
    color: $gray-900;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem;
  }

  .form-control {
    // Placeholder
    padding-left: 2rem;
    font-size: 1rem;
    transition: .2s ease background;

    &::placeholder {
      color: rgba($bd-topaz-extra-light, .75);
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: .75;
    }

    &.form-control-dark {
      color: $bd-topaz-extra-light;
      background: darken($cf-dark, 3%);
      border-color: transparent;

      @include media-breakpoint-up(md) {
        background: transparent;
      }

      &:focus {
        background: darken($cf-dark, 3%);
        border-color: transparent;
        box-shadow: none;

        &::placeholder {
          color: rgba($bd-topaz-extra-light, .55);
          // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
          opacity: .55;
        }
      }
    }
  }

  .bd-search {
    position: relative;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;

    i {
      position: absolute;
      left: 10px;
      font-size: 15px;
      color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    .bd-search {
      width: 18.2%;
    }
  }

  @include media-breakpoint-down(sm) {
    .bd-search {
      width: 100%;

      .form-control {
        width: 100%;
      }
    }
  }
}

.collapsing {
  transition: height .1s ease;
}
