/*!
 * Codefresh Docs (https://codefresh.io)
 * Copyright 2018 Codefresh, Inc.
 */
/*!
 * Bootstrap Grid v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1274px) {
  .container {
    max-width: 1214px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1274px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1274px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1274px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

:root {
  --font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-monospace: "Source Code Pro", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", monospace;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #03363d;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code,
kbd,
pre,
samp {
  font-family: "Source Code Pro", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", monospace;
}

code.highlighter-rouge {
  color: #ad6800;
  background-color: #fffbe6;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-family: roboto, sans-serif;
  font-weight: 700;
  color: #212438;
}

h1,
.h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 38px;
}

h2,
.h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

h3,
.h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}

h4,
.h4 {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

h5,
.h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.bd-navbar {
  min-height: 50px;
  background: #212437;
}

@media (max-width: 991.98px) {
  .bd-navbar .navbar-nav-scroll {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    overflow: hidden;
    font-size: .875rem;
  }
  .bd-navbar .navbar-nav-scroll .navbar-nav {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .bd-navbar .navbar-nav-scroll::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    pointer-events: none;
    content: "";
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #212437);
  }
}

@media (max-width: 767.98px) {
  .bd-navbar .navbar-nav-scroll {
    padding-top: .6rem;
    padding-bottom: .6rem;
  }
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-navbar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1071;
    }
  }
}

@media (min-width: 768px) {
  .bd-navbar .navbar-nav-scroll {
    margin-left: 2rem;
  }
}

@media (min-width: 992px) {
  .bd-navbar .navbar-nav-scroll {
    margin-left: 6.8rem;
  }
}

.bd-navbar .cf-badge-doc {
  font-size: 16px;
  color: #5fffbf;
  background: #212437;
}

.bd-navbar .navbar-nav .nav-item {
  padding-right: 1.2rem;
  margin-right: 1.5rem;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .bd-navbar .navbar-nav .nav-item {
    padding-right: 0;
    margin-right: 0;
  }
}

.bd-navbar .navbar-nav .nav-link {
  position: relative;
  height: 35px;
  padding: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  background-color: transparent;
  transition: background-color .1s linear, color .1s linear;
}

.bd-navbar .navbar-nav .nav-link::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  content: "";
  background-color: transparent;
  transition: background-color .1s linear, height .1s linear;
}

@media (min-width: 768px) {
  .bd-navbar .navbar-nav .nav-link {
    height: 60px;
    padding: 0 1rem;
    line-height: 60px;
    text-wrap: nowrap;
  }
}

.bd-navbar .navbar-nav .nav-link.active, .bd-navbar .navbar-nav .nav-link:hover {
  color: #11b5a4;
  text-wrap: nowrap;
}

.bd-navbar .navbar-nav .nav-link.active::after, .bd-navbar .navbar-nav .nav-link:hover::after {
  height: 2px;
  content: "";
  background-color: #11b5a4;
}

@media (min-width: 768px) {
  .bd-navbar .navbar-nav .nav-link.active::after, .bd-navbar .navbar-nav .nav-link:hover::after {
    height: 4px;
  }
}

.bd-navbar .navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.bd-navbar .dropdown-menu {
  font-size: .875rem;
}

.bd-navbar .dropdown-item.active {
  font-weight: 500;
  color: #212529;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: .4rem .6rem;
  background-size: .75rem .75rem;
}

.bd-navbar .form-control {
  padding-left: 2rem;
  font-size: 1rem;
  transition: .2s ease background;
}

.bd-navbar .form-control::-webkit-input-placeholder {
  color: rgba(228, 243, 241, 0.75);
  opacity: .75;
}

.bd-navbar .form-control::-moz-placeholder {
  color: rgba(228, 243, 241, 0.75);
  opacity: .75;
}

.bd-navbar .form-control:-ms-input-placeholder {
  color: rgba(228, 243, 241, 0.75);
  opacity: .75;
}

.bd-navbar .form-control::-ms-input-placeholder {
  color: rgba(228, 243, 241, 0.75);
  opacity: .75;
}

.bd-navbar .form-control::placeholder {
  color: rgba(228, 243, 241, 0.75);
  opacity: .75;
}

.bd-navbar .form-control.form-control-dark {
  color: #e4f3f1;
  background: #1b1e2d;
  border-color: transparent;
}

@media (min-width: 768px) {
  .bd-navbar .form-control.form-control-dark {
    background: transparent;
  }
}

.bd-navbar .form-control.form-control-dark:focus {
  background: #1b1e2d;
  border-color: transparent;
  box-shadow: none;
}

.bd-navbar .form-control.form-control-dark:focus::-webkit-input-placeholder {
  color: rgba(228, 243, 241, 0.55);
  opacity: .55;
}

.bd-navbar .form-control.form-control-dark:focus::-moz-placeholder {
  color: rgba(228, 243, 241, 0.55);
  opacity: .55;
}

.bd-navbar .form-control.form-control-dark:focus:-ms-input-placeholder {
  color: rgba(228, 243, 241, 0.55);
  opacity: .55;
}

.bd-navbar .form-control.form-control-dark:focus::-ms-input-placeholder {
  color: rgba(228, 243, 241, 0.55);
  opacity: .55;
}

.bd-navbar .form-control.form-control-dark:focus::placeholder {
  color: rgba(228, 243, 241, 0.55);
  opacity: .55;
}

.bd-navbar .bd-search {
  position: relative;
  padding: 0;
  margin-right: 0;
  margin-left: 0;
  border: 0;
}

.bd-navbar .bd-search i {
  position: absolute;
  left: 10px;
  font-size: 15px;
  color: #fff;
}

@media (min-width: 768px) {
  .bd-navbar .bd-search {
    width: 18.2%;
  }
}

@media (max-width: 767.98px) {
  .bd-navbar .bd-search {
    width: 100%;
  }
  .bd-navbar .bd-search .form-control {
    width: 100%;
  }
}

.collapsing {
  transition: height .1s ease;
}

.bd-masthead {
  position: relative;
  padding: 1rem 15px;
  background: #2a2b3d;
}

.bd-masthead h1 {
  line-height: 1;
}

.bd-masthead .cf-badge-doc {
  color: #5fffbf;
  text-transform: none !important;
  vertical-align: middle;
}

.bd-masthead .btn {
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  border-radius: 4px;
}

.bd-masthead .btn-primary {
  color: #fff;
  background-color: #35384b;
  border-color: #35384b;
}

.bd-masthead .btn-secondary {
  color: #5fffbf;
  background-color: #2a2b3d;
  border: 1px solid #5fffbf;
}

.bd-masthead .carbonad {
  margin-top: 0 !important;
  margin-bottom: -3rem !important;
}

@media (min-width: 576px) {
  .bd-masthead {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .bd-masthead .carbonad {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .bd-masthead h1 {
    font-size: 4rem;
  }
  .bd-masthead .carbonad {
    margin-top: 3rem !important;
  }
}

.bd-masthead.bd-masthead-home {
  position: relative;
}

.bd-masthead.bd-masthead-home h2 {
  font-size: 40px;
  text-shadow: 0 1px 2px rgba(35, 31, 32, 0.35);
}

.bd-masthead.bd-masthead-home .lead {
  color: #f7f9f9;
}

.bd-masthead.bd-masthead-home::after, .bd-masthead.bd-masthead-home::before {
  position: absolute;
  top: 0;
  z-index: 0;
  display: block;
  width: 50vw;
  height: 100%;
  min-height: 370px;
  content: "";
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.bd-masthead.bd-masthead-home .banner-home-inner {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .bd-masthead.bd-masthead-home h2 {
    font-size: 1.875rem;
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  position: relative;
  background: #fff;
}

.masthead-followup .followup-card-item {
  background-color: #fff;
}

.masthead-followup .followup-card-item h4 {
  margin-bottom: 1rem;
}

.masthead-followup .followup-card-item a {
  color: #3d7c84;
}

.masthead-followup .followup-card-item a:hover {
  color: #11b5a4;
}

.masthead-followup .bd-clipboard {
  display: none;
}

.masthead-followup .highlight {
  padding: .5rem 0;
  background-color: transparent;
}

#carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.05);
}

#carbonads a {
  color: #333;
  text-decoration: none;
}

@media (min-width: 576px) {
  #carbonads {
    max-width: 330px;
    border-radius: 4px;
  }
}

.carbon-img {
  float: left;
  margin-left: -145px;
}

.carbon-poweredby {
  display: block;
  color: #777 !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

a {
  color: rgba(17, 181, 164, 0.99);
}

a:hover {
  color: #0a6f65;
}

a.disabled, a[disabled], a[disabled="disabled"] {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: .55;
}

a.disabled:focus, a[disabled]:focus, a[disabled="disabled"]:focus {
  text-decoration: none;
}

ul > li.disabled {
  cursor: not-allowed !important;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    max-width: 1440px;
  }
}

.docs-layout {
  z-index: 20;
  -webkit-animation: intro 300ms both;
  animation: intro 300ms both;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}

.docs-layout.container-fluid {
  max-width: 1440px;
}

.simple-layout .bd-content-inner {
  padding: 0 25px;
}

.simple-layout figure.highlight,
.simple-layout div.highlighter-rouge {
  margin: 0 -25px 15px;
}

.bd-content {
  position: relative;
  -ms-flex-order: 1;
  order: 1;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .bd-content {
    max-width: 660px;
  }
}

@media (min-width: 1600px) {
  .bd-content {
    max-width: 880px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.bd-content-inner {
  background: #fff;
}

.bd-content-inner > h2[id],
.bd-content-inner > h3[id],
.bd-content-inner > h4[id] {
  pointer-events: none;
}

.bd-content-inner > h2[id] > div,
.bd-content-inner > h2[id] > a,
.bd-content-inner > h3[id] > div,
.bd-content-inner > h3[id] > a,
.bd-content-inner > h4[id] > div,
.bd-content-inner > h4[id] > a {
  pointer-events: auto;
}

.bd-content-inner > h2[id] > a,
.bd-content-inner > h3[id] > a,
.bd-content-inner > h4[id] > a {
  word-break: break-all;
}

.bd-content-inner > h2[id]::before,
.bd-content-inner > h3[id]::before,
.bd-content-inner > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
  content: "";
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"]) {
  color: #34af9d;
  border-bottom: none;
  transition: color .3s ease-in-out, border-bottom .3s ease-in-out;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"]):hover {
  color: #228164;
  text-decoration: none;
  border-bottom: 1px solid #228164;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"])[href^="https://g.codefresh.io/"] {
  color: #508eeb;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"])[href^="https://g.codefresh.io/"]::after {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 2px;
  vertical-align: middle;
  content: "";
  background: url("../../images/icons/codefresh-leaves.png") no-repeat;
  background-size: contain;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"])[href^="https://g.codefresh.io/"]:hover {
  color: #646fd4;
  border-bottom: 1px solid #646fd4;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"]):not([href^="https://g.codefresh.io/"]):not([href*="/docs/"]):not([href^="#"]) {
  color: #508eeb;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"]):not([href^="https://g.codefresh.io/"]):not([href*="/docs/"]):not([href^="#"])::after {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 2px;
  vertical-align: middle;
  content: "";
  background: url("../../images/icons/external-link.png") no-repeat;
  background-size: contain;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"]):not([href^="https://g.codefresh.io/"]):not([href*="/docs/"]):not([href^="#"]):hover {
  color: #646fd4;
  border-bottom: 1px solid #646fd4;
}

.bd-content-inner a:not([data-type="image"]):not([data-anchorjs-icon="#"])[href^="https://www.youtube.com/"]::after {
  display: none !important;
}

.bd-content-inner .borderless-table td {
  border: none !important;
}

.bd-content-inner > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
  .bd-content-inner > table {
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .bd-content-inner > table.table-bordered {
    border: 0 !important;
  }
}

.bd-content-inner > table > thead > tr > th,
.bd-content-inner > table > thead > tr > td,
.bd-content-inner > table > tbody > tr > th,
.bd-content-inner > table > tbody > tr > td,
.bd-content-inner > table > tfoot > tr > th,
.bd-content-inner > table > tfoot > tr > td {
  padding: .6rem;
  font-size: .875rem;
  vertical-align: top;
  border: 1px solid rgba(61, 124, 132, 0.2);
}

.bd-content-inner > table > thead > tr > th > p:last-child,
.bd-content-inner > table > thead > tr > td > p:last-child,
.bd-content-inner > table > tbody > tr > th > p:last-child,
.bd-content-inner > table > tbody > tr > td > p:last-child,
.bd-content-inner > table > tfoot > tr > th > p:last-child,
.bd-content-inner > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}

.bd-content-inner > table > thead > tr > th > .highlight,
.bd-content-inner > table > thead > tr > td > .highlight,
.bd-content-inner > table > tbody > tr > th > .highlight,
.bd-content-inner > table > tbody > tr > td > .highlight,
.bd-content-inner > table > tfoot > tr > th > .highlight,
.bd-content-inner > table > tfoot > tr > td > .highlight {
  padding-right: .5rem;
  padding-left: .5rem;
  margin-top: .625rem;
  margin-right: 0;
  margin-left: 0;
}

.bd-content-inner > table > thead > tr > th .btn-clipboard,
.bd-content-inner > table > thead > tr > td .btn-clipboard,
.bd-content-inner > table > tbody > tr > th .btn-clipboard,
.bd-content-inner > table > tbody > tr > td .btn-clipboard,
.bd-content-inner > table > tfoot > tr > th .btn-clipboard,
.bd-content-inner > table > tfoot > tr > td .btn-clipboard {
  right: 0 !important;
}

.bd-content-inner > table thead {
  color: #f7f9f9;
  background: #3d7c84;
}

.bd-content-inner > table tbody tr:hover {
  background: #f7f9f9;
}

.bd-content-inner > table td:first-child > code {
  white-space: nowrap;
}

.bd-content-inner > p > img {
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bd-content-inner figcaption {
  font-size: 1rem;
  color: #3d7c84;
}

.bd-content-inner {
  position: relative;
}

.bd-content-inner > h1 {
  margin-top: 3rem;
}

.bd-content-inner > h2:not(:first-child) {
  margin-top: 3rem;
}

.bd-content-inner > h3 {
  margin-top: 2.25rem;
}

.bd-content-inner > h4 {
  margin-top: 2.25rem;
  margin-bottom: .5rem;
}

.bd-content-inner > h4::after {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 2px;
  content: "";
  background-color: #dce7e8;
}

.bd-content-inner > h5 {
  margin-top: 2.25rem;
}

.bd-content-inner > ul {
  padding-left: 1rem;
}

.bd-content-inner > ul li,
.bd-content-inner > ol li {
  margin-top: .625rem;
}

@media (min-width: 992px) {
  .bd-content-inner > ul,
  .bd-content-inner > ol,
  .bd-content-inner > p {
    max-width: 100%;
  }
}

.bd-content-inner > ol {
  padding-left: 1.3rem;
}

.bd-content-inner .bd-content-top-controls {
  position: absolute;
  top: -1px;
  right: -1px;
  left: 0;
  min-width: 50px;
  overflow: hidden;
  text-align: right;
  background: transparent;
}

.bd-content-inner .bd-content-top-controls .dropdown-toggle.btn-control-dropdown {
  padding: .375rem;
  color: #0ab3ca;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bd-content-inner .bd-content-top-controls .dropdown-toggle.btn-control-dropdown::before {
  content: none;
}

.bd-content-inner .bd-content-top-controls .dropdown-toggle.btn-control-dropdown.focus, .bd-content-inner .bd-content-top-controls .dropdown-toggle.btn-control-dropdown:focus {
  box-shadow: none;
}

.bd-content-inner .bd-content-top-controls .dropleft {
  display: none;
}

@media (min-width: 768px) {
  .bd-content-inner .bd-content-top-controls .dropleft {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.bd-content-inner .bd-content-top-controls .dropleft .dropdown-toggle.btn-control-dropdown {
  z-index: 1;
}

.bd-content-inner .bd-content-top-controls .dropleft .dropdown-toggle.btn-control-dropdown i.fas {
  transition: all .2s linear;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.bd-content-inner .bd-content-top-controls .dropleft.show .dropdown-toggle {
  border-bottom-left-radius: 0;
}

.bd-content-inner .bd-content-top-controls .dropleft.show .dropdown-toggle.btn-control-dropdown i.fas {
  -webkit-transform: rotate(62deg);
  transform: rotate(62deg);
}

.bd-content-inner .bd-content-top-controls .dropleft .dropdown-menu {
  top: 0 !important;
  right: 0;
  left: auto !important;
  z-index: 0;
  display: inline-block;
  min-height: 38px;
  padding: .25rem 0;
  margin-right: -.115rem;
  visibility: hidden;
  border-top-color: transparent;
  border-right-color: transparent;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all .3s ease-in-out;
  -webkit-transform: translateX(130px) !important;
  transform: translateX(130px) !important;
}

.bd-content-inner .bd-content-top-controls .dropleft .dropdown-menu.show {
  visibility: visible;
  -webkit-transform: translateX(-30px) !important;
  transform: translateX(-30px) !important;
}

.bd-content-inner .bd-content-top-controls .dropleft .dropdown-menu .form-group {
  padding: 0 5px;
  margin-bottom: 0;
}

.bd-lead {
  font-size: 1.125rem;
  font-weight: 300;
  color: #3d7c84;
}

@media (min-width: 576px) {
  .bd-lead {
    max-width: 80%;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
}

.bd-improve-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.bd-improve-link a {
  display: block;
  padding: .375rem 0;
  font-size: 1rem;
  color: #3d7c84 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.bd-improve-link a:hover {
  color: #11b5a4 !important;
}

.bd-improve-link a::after {
  display: none !important;
}

.bd-text-purple {
  color: #563d7c;
}

.bd-text-purple-bright {
  color: #7952b3;
}

.bd-text-topaz-main {
  color: #11b5a4;
}

.bd-text-white {
  color: #5fffbf;
}

#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #0b6f63;
  outline: 0;
}

#skippy .skiplink-text {
  padding: .5em;
  outline: 1px dotted;
}

.bd-footer {
  font-size: 85%;
  text-align: center !important;
  background-color: #2a2b3d !important;
}

.bd-footer a {
  color: #8c91c0;
  border-bottom: 1px solid rgba(61, 124, 132, 0.2);
  transition: background .2s ease, border-color, .2s ease;
}

.bd-footer a:hover, .bd-footer a:focus {
  color: #8c91c0;
  text-decoration: none;
  border-bottom-color: #8c91c0;
}

.bd-footer p {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.5) !important;
}

@media (min-width: 576px) {
  .bd-footer {
    text-align: left;
  }
}

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;
}

.bd-footer-links li {
  display: inline-block;
}

.bd-footer-links li + li {
  margin-left: 1rem;
}

.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row .row + .row {
  margin-top: 1rem;
}

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-highlight {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.example-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.example-content-main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-main {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-main {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.example-content-secondary {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-secondary {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-secondary {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.bd-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

.bd-example-container-header {
  height: 3rem;
  margin-bottom: .5rem;
  background-color: white;
  border-radius: .25rem;
}

.bd-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: #80bdff;
  border-radius: .25rem;
}

.bd-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: #957bbe;
  border-radius: .25rem;
}

.bd-example-container-fluid {
  max-width: none;
}

.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -15px 0;
  border: solid #f7f7f9;
  border-width: .2rem 0 0;
}

.bd-example::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 576px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
  }
}

.bd-example + .highlight,
.bd-example + .clipboard + .highlight {
  margin-top: 0;
}

.bd-example + p {
  margin-top: 2rem;
}

.bd-example .pos-f-t {
  position: relative;
  margin: -1rem;
}

@media (min-width: 576px) {
  .bd-example .pos-f-t {
    margin: -1.5rem;
  }
}

.bd-example > .form-control + .form-control {
  margin-top: .5rem;
}

.bd-example > .nav + .nav,
.bd-example > .alert + .alert,
.bd-example > .navbar + .navbar,
.bd-example > .progress + .progress,
.bd-example > .progress + .btn {
  margin-top: 1rem;
}

.bd-example > .dropdown-menu:first-child {
  position: static;
  display: block;
}

.bd-example > .form-group:last-child {
  margin-bottom: 0;
}

.bd-example > .close {
  float: none;
}

.bd-example-type .table .type-info {
  color: #999;
  vertical-align: middle;
}

.bd-example-type .table td {
  padding: 1rem 0;
  border-color: #eee;
}

.bd-example-type .table tr:first-child td {
  border-top: 0;
}

.bd-example-type h1,
.bd-example-type h2,
.bd-example-type h3,
.bd-example-type h4,
.bd-example-type h5,
.bd-example-type h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.bd-example-bg-classes p {
  padding: 1rem;
}

.bd-example > img + img {
  margin-left: .5rem;
}

.bd-example > .btn-group {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.bd-example > .btn-toolbar + .btn-toolbar {
  margin-top: .5rem;
}

.bd-example-control-sizing select,
.bd-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: .5rem;
}

.bd-example-form .input-group {
  margin-bottom: .5rem;
}

.bd-example > textarea.form-control {
  resize: vertical;
}

.bd-example > .list-group {
  max-width: 400px;
}

.bd-example .fixed-top,
.bd-example .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}

.bd-example .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}

@media (min-width: 576px) {
  .bd-example .fixed-top,
  .bd-example .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .bd-example .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}

.bd-example .pagination {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.modal {
  z-index: 1072;
}

.modal .tooltip,
.modal .popover {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}

.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

.bd-example-popover-static {
  padding-bottom: 1.5rem;
  background-color: #f9f9f9;
}

.bd-example-popover-static .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 1.25rem;
}

.tooltip-demo a {
  white-space: nowrap;
}

.bd-example-tooltip-static .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}

.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: .25rem;
  background-color: #f5f5f5;
}

.bd-example-border-utils-0 [class^="border"] {
  border: 1px solid #dee2e6;
}

.highlight {
  padding: 2rem .9375rem;
  margin: 1rem 0;
  background-color: #243a41;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) {
  .highlight {
    padding: 2rem 1.5rem;
    border-radius: 6px;
  }
}

.bd-content .highlight {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 1600px) {
  .bd-content .highlight {
    margin-right: -1.5rem;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-right: 0 !important;
  margin-bottom: 0;
  margin-left: 0 !important;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  font-size: inherit;
  color: #fff;
}

.btn-link {
  color: #11b5a4;
}

.btn-link:hover {
  color: #11b5a4;
  border-color: transparent;
}

.btn-bd-primary {
  font-weight: 500;
  color: #fff;
  background-color: #35384b;
  border-color: none;
  border-radius: 4px;
}

.btn-bd-primary:hover, .btn-bd-primary:active {
  color: #fff;
  background-color: #7952b3;
  border-color: #7952b3;
}

.btn-bd-download {
  font-weight: 500;
  color: #ffe484;
  border-color: #ffe484;
}

.btn-bd-download:hover, .btn-bd-download:active {
  color: #231f20;
  background-color: #ffe484;
  border-color: #ffe484;
}

.btn-get-started {
  color: #235f5d;
  background-color: #fff;
  border-radius: 5px;
}

.btn-get-started:hover, .btn-get-started:active {
  color: #fff;
  background-color: #389794;
}

.btn-get-beta {
  color: #fff;
  background-color: #235f5d;
  border-radius: 5px;
}

.btn-get-beta:hover, .btn-get-beta:active {
  color: #fff;
  background-color: #389794;
}

.switch {
  position: relative;
  font-size: 1rem;
}

.switch input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  background: none;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
}

.switch input + label {
  position: relative;
  display: inline-block;
  min-width: calc(calc(2.375rem * .8) * 2);
  height: calc(2.375rem * .8);
  margin-bottom: 0;
  line-height: calc(2.375rem * .8);
  text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: calc(2.375rem * .8);
  outline: none;
}

.switch input + label::before,
.switch input + label::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: calc(calc(2.375rem * .8) * 2);
  content: "";
}

.switch input + label::before {
  right: 0;
  background-color: #dee2e6;
  border-radius: calc(2.375rem * .8);
  transition: 0.2s all;
}

.switch input + label::after {
  top: 2px;
  left: 2px;
  width: calc(calc(2.375rem * .8) - calc(2px * 2));
  height: calc(calc(2.375rem * .8) - calc(2px * 2));
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s all;
}

.switch input[checked] + label::before,
.switch input:checked + label::before {
  background-color: #08d;
}

.switch input[checked] + label::after,
.switch input:checked + label::after {
  margin-left: calc(2.375rem * .8);
}

.switch input:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25);
}

.switch input[disabled] + label,
.switch input:disabled + label {
  color: #868e96;
  cursor: not-allowed;
}

.switch input[disabled] + label::before,
.switch input:disabled + label::before {
  background-color: #e9ecef;
}

.switch.switch-sm {
  font-size: 0.875rem;
}

.switch.switch-sm input + label {
  min-width: calc(calc(1.4375rem * .8) * 2);
  height: calc(1.4375rem * .8);
  line-height: calc(1.4375rem * .8);
  text-indent: calc(calc(calc(1.4375rem * .8) * 2) + .5rem);
}

.switch.switch-sm input + label::before {
  width: calc(calc(1.4375rem * .8) * 2);
}

.switch.switch-sm input + label::after {
  width: calc(calc(1.4375rem * .8) - calc(2px * 2));
  height: calc(calc(1.4375rem * .8) - calc(2px * 2));
}

.switch.switch-sm input[checked] + label::after,
.switch.switch-sm input:checked + label::after {
  margin-left: calc(1.4375rem * .8);
}

.switch.switch-lg {
  font-size: 1.25rem;
}

.switch.switch-lg input + label {
  min-width: calc(calc(3rem * .8) * 2);
  height: calc(3rem * .8);
  line-height: calc(3rem * .8);
  text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);
}

.switch.switch-lg input + label::before {
  width: calc(calc(3rem * .8) * 2);
}

.switch.switch-lg input + label::after {
  width: calc(calc(3rem * .8) - calc(2px * 2));
  height: calc(calc(3rem * .8) - calc(2px * 2));
}

.switch.switch-lg input[checked] + label::after,
.switch.switch-lg input:checked + label::after {
  margin-left: calc(3rem * .8);
}

.switch + .switch {
  margin-left: 1rem;
}

.bd-toc {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .bd-toc {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .bd-toc {
    -ms-flex-order: 2;
    order: 2;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.section-nav {
  padding-left: 0;
}

.section-nav ul {
  display: block;
  padding-left: 1rem;
}

.section-nav ul ul {
  display: none;
}

.toc-title {
  font-size: .875rem;
}

.toc-entry {
  display: block;
}

.toc-entry a {
  position: relative;
  display: inline-block;
  padding: .125rem 0;
  margin-top: 5px;
  line-height: 1.3;
  color: #3d7c84;
  transition: color .2s linear, height .2s linear;
}

.toc-entry a::before {
  position: absolute;
  top: 0;
  left: -15px;
  height: 0;
  content: "";
  border-left: 3px solid #10aa9a;
  transition: height .2s linear;
  transition-delay: .1s;
}

.toc-entry a:hover {
  color: #11b5a4;
  text-decoration: none;
}

.toc-entry a.active {
  color: #212437;
  text-decoration: none;
  transition-delay: 0;
}

.toc-entry a.active::before {
  top: 0;
  height: 100%;
}

.bd-sidebar {
  position: relative;
  -ms-flex-order: 1;
  order: 1;
  background-color: #f7f9f9;
  border-right: 1px solid #d6e2e4;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 60px;
      z-index: 1000;
      height: calc(100vh - 60px);
    }
  }
}

@media (min-width: 1274px) {
  .bd-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

@media (min-width: 1600px) {
  .bd-sidebar {
    -ms-flex: 0 1 385px;
    flex: 0 1 385px;
  }
}

@media (min-width: 2000px) {
  .bd-sidebar {
    position: fixed;
    left: 0;
    max-width: 380px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-links {
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .bd-links {
    display: block !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1274px) {
  .bd-links {
    position: fixed;
    top: 60px;
    max-width: 1300px;
    height: 100%;
    padding-top: 2rem;
    padding-left: 999px;
    margin-left: -999px;
    background: #f7f9f9;
  }
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-search + .bd-links {
      max-height: calc(100vh - 8.5rem);
    }
  }
}

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.bd-search .form-control:focus {
  border-color: rgba(17, 181, 164, 0.4);
  box-shadow: 0 0 0 3px rgba(17, 181, 164, 0.22);
}

.bd-search-docs-toggle {
  line-height: 1;
  color: #f7f9f9;
}

.bd-search-docs-toggle:hover {
  color: #0a6f65;
}

.bd-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  margin-bottom: 0;
  font-size: .75rem;
  color: #3d7c84 !important;
  text-transform: uppercase;
  transition: color 200ms linear;
}

@media (min-width: 992px) {
  .bd-toc-link {
    font-size: .875rem;
  }
}

.bd-toc-link:hover {
  text-decoration: none;
}

.bd-nav-links-item {
  position: relative;
  padding-bottom: .4rem;
}

.bd-nav-links-item:not(:first-child) {
  margin-top: 1rem;
}

.bd-nav-links-item > .bd-nav-links-item-group {
  cursor: pointer;
}

.bd-nav-links-item > .bd-nav-links-item-group > span {
  display: inline-block;
  width: 15px;
  height: auto;
  margin-left: 3px;
  font-size: .75rem;
  text-align: center;
}

.bd-nav-links-item > .bd-nav-links-item-group > span .fa-cf-arrow::before {
  content: "\f078";
}

.bd-nav-links-item > .bd-sidenav {
  display: block;
  margin-top: 1rem;
  margin-left: 23px;
}

.bd-nav-links-item > .bd-sidenav .bd-sidenav > li {
  width: 100%;
  padding: 0 1.5rem;
}

.bd-nav-links-item > .bd-sidenav .opened .bd-sidenav {
  display: block;
  width: 100%;
}

.bd-nav-links-item.opened {
  padding-bottom: 1rem;
}

.bd-nav-links-item.opened:not(:first-child) {
  margin-top: 1rem;
}

.bd-nav-links-item.opened > .bd-nav-links-item-group > span .fa-cf-arrow::before {
  content: "\f077";
}

.bd-nav-links-item.active > .bd-toc-link {
  color: #212437 !important;
}

.bd-nav-links-item.active > .bd-toc-link:hover {
  color: #11b4a3;
  background-color: transparent;
}

.bd-sidebar .nav > li > a {
  display: block;
  padding: .21rem 1.5rem;
  font-size: .875rem;
  font-weight: 400;
  color: #03363d;
  transition: color 200ms linear;
}

.bd-sidebar .nav > li > a:hover {
  color: #0f9d8e;
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > li > a > span {
  display: inline-block;
  width: 20px;
  height: auto;
  margin-left: 1px;
  font-size: .75rem;
  text-align: center;
}

.bd-sidebar .nav > li > a > span .fa-cf-arrow::before {
  content: "\f078";
}

.bd-sidebar .nav > li.opened > a > span .fa-cf-arrow::before {
  content: "\f077";
}

.bd-sidebar .nav > li.active > a {
  position: relative;
}

.bd-sidebar .nav > li.active > a::before {
  position: absolute;
  top: 10%;
  left: 5px;
  height: 80%;
  content: "";
  border-left: 3px solid #11b5a4;
  transition: height 200ms linear;
}

.bd-sidebar .nav > li.active.has-children > a {
  margin-left: 0;
}

.bd-sidebar .nav > li.active.has-children > a::before {
  left: 0;
  border-color: transparent;
}

/*
.bd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  font-weight: 500;
  color: rgba($bd-doc-sidebar-link, 1);
  transition: color 200ms linear;
}
*/
/*
.bd-sidebar .nav > li > a:hover {
  color: rgba($bd-doc-sidebar-link-active, 1);
  text-decoration: none;
  background-color: transparent;
}
*/
/*
.bd-sidebar .nav > .active > a {
  position: relative;
  &::before {
    position: absolute;
    top: 10%;
    left: 5px;
    height: 80%;
    content: "";
    border-left: 3px solid rgba($bd-doc-sidebar-link-active, .4);
    transition: height 200ms linear;
  }
}
*/
.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 600;
  color: #212437;
  background-color: transparent;
}

blockquote,
.blockquote,
.bd-callout {
  position: relative;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: 1rem;
  background: rgba(255, 204, 48, 0.15);
}

blockquote::before,
.blockquote::before,
.bd-callout::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  content: "";
  background-color: #ffcc30;
}

@media (min-width: 1600px) {
  blockquote,
  .blockquote,
  .bd-callout {
    margin: 1.5rem 0;
  }
}

blockquote p:last-child,
.blockquote p:last-child,
.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: .25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

.bd-callout-info {
  background: rgba(255, 204, 48, 0.15);
}

.bd-callout-info::before {
  background-color: #ffcc30;
}

.bd-callout-info h4 {
  color: #ffcc30;
}

.bd-callout-warning {
  background: rgba(217, 83, 79, 0.15);
}

.bd-callout-warning::before {
  background-color: #d9534f;
}

.bd-callout-warning h4 {
  color: #d9534f;
}

.bd-callout-success {
  background: rgba(3, 199, 124, 0.15);
}

.bd-callout-success::before {
  background-color: #03c77c;
}

.bd-callout-success h4 {
  color: #03c77c;
}

.bd-callout-tip {
  background: rgba(80, 142, 235, 0.15);
}

.bd-callout-tip::before {
  background-color: #508eeb;
}

.bd-callout-tip h4 {
  color: #508eeb;
}

.bd-examples .img-thumbnail {
  margin-bottom: .75rem;
}

.bd-examples h4 {
  margin-bottom: .25rem;
}

.bd-examples p {
  margin-bottom: 1.25rem;
}

@media (max-width: 480px) {
  .bd-examples {
    margin-right: -.75rem;
    margin-left: -.75rem;
  }
  .bd-examples > [class^="col-"] {
    padding-right: .75rem;
    padding-left: .75rem;
  }
}

.bd-browser-bugs td p {
  margin-bottom: 0;
}

.bd-browser-bugs th:first-child {
  width: 18%;
}

.bd-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  color: #563d7c;
  background-color: #f9f9f9;
  border-radius: .25rem;
}

.bd-brand-item {
  padding: 4rem 0;
  text-align: center;
}

.bd-brand-item + .bd-brand-item {
  border-top: 1px solid #fff;
}

.bd-brand-logos .inverse {
  color: #fff;
  background-color: #563d7c;
}

.bd-brand-item h1,
.bd-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.bd-brand-item .bd-booticon {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .bd-brand-item {
    display: table-cell;
    width: 1%;
  }
  .bd-brand-item + .bd-brand-item {
    border-top: 0;
    border-left: 1px solid #fff;
  }
  .bd-brand-item h1 {
    font-size: 4rem;
  }
}

.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
}

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: .25rem;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 6rem;
    height: 6rem;
  }
}

.color-swatches .bd-purple {
  background-color: #563d7c;
}

.color-swatches .bd-purple-light {
  background-color: #cbbde2;
}

.color-swatches .bd-purple-lighter {
  background-color: #e5e1ea;
}

.color-swatches .bd-gray {
  background-color: #f9f9f9;
}

.swatch-blue {
  color: #fff;
  background-color: #007bff;
}

.swatch-indigo {
  color: #fff;
  background-color: #6610f2;
}

.swatch-purple {
  color: #fff;
  background-color: #6f42c1;
}

.swatch-pink {
  color: #fff;
  background-color: #e83e8c;
}

.swatch-red {
  color: #fff;
  background-color: #dc3545;
}

.swatch-orange {
  color: #212529;
  background-color: #fd7e14;
}

.swatch-yellow {
  color: #212529;
  background-color: #ffc107;
}

.swatch-green {
  color: #fff;
  background-color: #28a745;
}

.swatch-teal {
  color: #fff;
  background-color: #20c997;
}

.swatch-cyan {
  color: #fff;
  background-color: #17a2b8;
}

.swatch-white {
  color: #212529;
  background-color: #fff;
}

.swatch-gray {
  color: #fff;
  background-color: #6c757d;
}

.swatch-gray-dark {
  color: #fff;
  background-color: #343a40;
}

.swatch-primary {
  color: #fff;
  background-color: #08d;
}

.swatch-100 {
  color: #212529;
  background-color: #f8f9fa;
}

.swatch-200 {
  color: #212529;
  background-color: #e9ecef;
}

.swatch-300 {
  color: #212529;
  background-color: #dee2e6;
}

.swatch-400 {
  color: #212529;
  background-color: #ced4da;
}

.swatch-500 {
  color: #212529;
  background-color: #adb5bd;
}

.swatch-600 {
  color: #fff;
  background-color: #6c757d;
}

.swatch-700 {
  color: #fff;
  background-color: #495057;
}

.swatch-800 {
  color: #fff;
  background-color: #343a40;
}

.swatch-900 {
  color: #fff;
  background-color: #212529;
}

.bd-clipboard {
  position: relative;
  display: none;
  float: right;
}

.bd-clipboard + .highlight {
  margin-top: 0;
}

.btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: #3d7c84;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 0;
  border-top-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

@media (min-width: 768px) {
  .btn-clipboard {
    right: -1rem;
  }
}

@media (min-width: 1600px) {
  .btn-clipboard {
    top: 0;
    right: -1.5rem;
  }
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #11b5a4;
}

@media (min-width: 768px) {
  .bd-clipboard {
    display: block;
  }
}

.bulb {
  position: relative;
}

.bulb::before, .bulb::after {
  content: "";
}

.bulb::before {
  position: absolute;
  top: -9px;
  left: 16px;
  display: inline-block;
  padding: 2px 5px;
  font-size: 10px;
  line-height: 11px;
  color: #fff;
  content: attr(data-bulb-text);
  background: #231f20;
  border-radius: 3px;
}

.bulb::after {
  position: absolute;
  top: 6px;
  left: 34px;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px solid #231f20;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.bulb.bulb-above-left::before {
  left: 0;
}

.bulb.bulb-above-left::after {
  left: 6px;
}

.bulb.bulb-above-right::before {
  right: 0;
  left: auto;
}

.bulb.bulb-above-right::after {
  right: 6px;
  left: auto;
}

.bulb.bulb-pos-after::before {
  top: -15px;
}

.bulb.bulb-pos-after::after {
  top: 0;
}

.bulb.bulb-left::before {
  top: 0;
  left: -56px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

.bulb.bulb-left::after {
  top: 0;
  left: -25px;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.bulb.bulb-red::before {
  color: #fff;
  background: rgba(217, 83, 79, 0.9);
}

.bulb.bulb-red::after {
  color: #fff;
  border-top: 4px solid rgba(217, 83, 79, 0.9);
}

.bulb.bulb-red.bulb-left::after {
  border-top-color: transparent;
  border-left: 4px solid rgba(217, 83, 79, 0.9);
  -webkit-transform: translateY(7.5px);
  transform: translateY(7.5px);
}

.cf-badge {
  font-size: 12px;
  font-weight: 700;
}

.cf-badge.cf-badge-new {
  color: #5fffbf;
  background-color: #212437;
}

body {
  padding-right: 0 !important;
}

.modal-open {
  overflow: auto !important;
}

.bd-modal {
  padding-right: 0 !important;
  pointer-events: none;
}

.bd-modal .modal-footer {
  background-color: #f9f9f9;
}

.bd-modal .modal-dialog {
  -ms-flex-align: end;
  align-items: flex-end;
}

.bd-modal .redirect-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #f3f3f3;
  border-radius: 50%;
  outline: none;
}

.bd-modal .redirect-popup__info-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #f3f3f3;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .bd-modal .modal-dialog {
    margin-right: 1.75rem;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .bd-modal .modal-dialog {
    max-width: 526px;
  }
}

.highlight {
  background-color: #264a54;
}

.hll {
  background-color: #ffc;
}

.c {
  color: #546e74;
}

.k {
  color: #e8f7f2;
}

.o {
  color: #e8f7f2;
}

.p {
  color: #3da693;
}

.cm {
  color: #546e74;
}

.cp {
  color: #099;
}

.c1 {
  color: #546e74;
}

.cs {
  color: #546e74;
}

.gd {
  background-color: #fcc;
  border: 1px solid #ff9da4;
}

.ge {
  font-style: italic;
}

.gr {
  color: #f7878e;
}

.gh {
  color: #030;
}

.gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}

.go {
  color: #aaa;
}

.gp {
  color: #009;
}

.gu {
  color: #fcc;
}

.gt {
  color: #9c6;
}

.kc {
  color: #3f80d0;
}

.kd {
  color: #e8f7f2;
}

.kn {
  color: #3f80d0;
}

.kp {
  color: #3f80d0;
}

.kr {
  color: #3f80d0;
}

.kt {
  color: #078;
}

.m {
  color: #f78132;
}

.s {
  color: #d1f1a9;
}

.na {
  color: #e8f7f2;
}

.nb {
  color: #e8f7f2;
}

.nc {
  color: #f57f7f;
}

.no {
  color: #5ac471;
}

.nd {
  color: #99f;
}

.ni {
  color: #999;
}

.ne {
  color: #ff9da4;
}

.nf {
  color: #de5aff;
}

.nl {
  color: #d695ff;
}

.nn {
  color: #0cf;
}

.nt {
  color: #f57f7f;
}

.nv {
  color: #d1f1a9;
}

.nx {
  color: #68bbde;
}

.ow {
  color: #000;
}

.pi {
  color: #e8f7f2;
}

.w {
  color: #bbb;
}

.mf {
  color: #f78132;
}

.mh {
  color: #f78132;
}

.mi {
  color: #f78132;
}

.mo {
  color: #f78132;
}

.sb {
  color: #d1f1a9;
}

.sc {
  color: #d1f1a9;
}

.sd {
  font-style: italic;
  color: #d1f1a9;
}

.s2 {
  color: #76c47e;
}

.se {
  color: #d1f1a9;
}

.sh {
  color: #d1f1a9;
}

.si {
  color: #3da693;
}

.sx {
  color: #d1f1a9;
}

.sr {
  color: #3aa;
}

.s1 {
  color: #d1f1a9;
}

.ss {
  color: #fc3;
}

.bp {
  color: #366;
}

.vc {
  color: #033;
}

.vg {
  color: #033;
}

.vi {
  color: #033;
}

.il {
  color: #f78132;
}

.si + .nx,
.nx + .p + .nx,
.si + .nx + .p + .nx {
  color: #e8f7f2;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

.highlight pre code.language-bash,
.highlight pre code.language-sh {
  color: #c1be61;
}

.language-bash::before,
.language-sh::before {
  color: #f57f7f;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.language-powershell::before {
  color: #f57f7f;
  content: "PM> ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchorjs-link {
  font-weight: 400;
  color: rgba(17, 181, 164, 0.5);
  transition: color .16s linear;
}

.anchorjs-link:hover {
  color: #0a6f65;
  text-decoration: none;
}

a {
  color: rgba(17, 181, 164, 0.99);
}

a:hover {
  color: #0a6f65;
}

a.disabled, a[disabled], a[disabled="disabled"] {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: .55;
}

a.disabled:focus, a[disabled]:focus, a[disabled="disabled"]:focus {
  text-decoration: none;
}

ul > li.disabled {
  cursor: not-allowed !important;
}

.bd-search--home {
  padding: 0;
}

.bd-search--home .doc-search-input {
  padding: 1rem 1.5rem;
}

.bd-search--home i,
.bd-search--home svg {
  position: absolute;
  right: 1.5rem;
  color: #3d7c84;
}

.bd-search--home .algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
}

.algolia-autocomplete {
  display: inline-block !important;
  -ms-flex: 1;
  flex: 1;
}

.algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  padding: .75rem 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

@media (min-width: 768px) {
  .algolia-autocomplete .ds-dropdown-menu {
    width: 175%;
  }
}

.algolia-autocomplete .ds-dropdown-menu::before {
  display: none !important;
}

.algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  padding: 0 !important;
  overflow: visible !important;
  background-color: transparent !important;
  border: 0 !important;
}

.algolia-autocomplete .ds-dropdown-menu .ds-suggestions {
  margin-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0 !important;
  overflow: visible !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: .125rem 1rem !important;
  margin-top: 0 !important;
  font-size: .875rem !important;
  font-weight: 500 !important;
  color: #0b6f63 !important;
  border-bottom: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none !important;
  padding-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
  text-align: left !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  display: none !important;
}

.algolia-autocomplete .ds-suggestion:not(:first-child) .algolia-docsearch-suggestion--category-header {
  padding-top: .75rem !important;
  margin-top: .75rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .ds-suggestion .algolia-docsearch-suggestion--subcategory-column {
  display: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  display: block;
  padding: .25rem 1rem !important;
  margin-bottom: 0 !important;
  font-size: .875rem !important;
  font-weight: 400 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--text {
  padding: 0 1rem .5rem !important;
  margin-top: -.25rem;
  font-size: .875rem !important;
  font-weight: 400;
  line-height: 1.25 !important;
}

.algolia-autocomplete .algolia-docsearch-footer {
  float: none !important;
  width: auto !important;
  height: 38px !important;
  padding: .75rem 1rem 0;
  font-size: 0 !important;
  line-height: 1 !important;
  color: #767676 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .algolia-docsearch-footer--logo {
  display: inline-block !important;
  width: 100px;
  color: inherit !important;
  text-indent: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #11b5a4;
  background-color: rgba(20, 187, 79, 0.12);
}

.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  box-shadow: inset 0 -2px 0 0 rgba(228, 243, 241, 0.99) !important;
}

.algolia-autocomplete .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
  background-color: rgba(52, 236, 61, 0.12) !important;
}

.helpbub-toggler {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px 4px 8px;
  color: #b0c4e7;
  cursor: pointer;
  background-color: #3f4255;
  border: 1px solid #35384b;
  border-radius: 16px;
}

.helpbub-toggler .helpbub-toggler--hotkey {
  color: #fff;
}

.helpbub-toggler:focus {
  outline: 1px solid #5fffbf;
  box-shadow: 0 0 0 5px rgba(95, 255, 191, 0.24);
}

.helpbub-toggler--small {
  max-width: 275px;
}

/* stylelint-disable selector-max-id */
#helphub-modal {
  inset: 0;
  margin: auto;
}
/*# sourceMappingURL=docs.min.css.map */