//
// Callouts
//

$backgroundTransparentFilterValue: .85;

blockquote,
.blockquote,
.bd-callout {
  position: relative;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: 1rem;
  background: transparentize($bd-info, $backgroundTransparentFilterValue);


  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    content: "";
    background-color: $bd-info;
  }

  @include media-breakpoint-up(xxl) {
    margin: 1.5rem 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: .25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

// Variations
@mixin bs-callout-variant($color) {
  background: transparentize($color, $backgroundTransparentFilterValue);

  &::before {
    background-color: $color;
  }

  h4 { color: $color; }
}

.bd-callout-info { @include bs-callout-variant($bd-info); }
.bd-callout-warning { @include bs-callout-variant($bd-warning); }
.bd-callout-success { @include bs-callout-variant($bd-success); }
.bd-callout-tip { @include bs-callout-variant($bd-tip); }
